import ChromeSvg from "@/lib/chromeSvg";
import banner from '@/lib/home-banner.jpg'
import banner1 from '@/lib/home-banner1.jpg'
import banner2 from '@/lib/home-banner2.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import FAQ from "../faq";
import Price from "../price";
import Guides from "@/guides";

// const VideoSlide = ({ url }) => {
//     return (
//         <ReactPlayer url={url} width="100%" playing={true} />
//     )
// }

const Home = () => {
    const navigate = useNavigate()

    const handleChromeInstall = () => {
        navigate('/chrome-install')
    }

    return (
        <>
            <div className="flex flex-col pt-14 pb-5 items-center">
                <p className="text-center font-semibold text-4xl ">
                    A powerful single-click tool to uncover customer insights
                </p>
                <p className="flex-col md:flex-row inline-block  text-gray-600 font-light pt-3">
                    Automatically extracts reviews data from <span className="text-base font-bold text-red-600 px-1">Etsy</span>  and outputs clean CSV files. Understand the true customer experience.
                </p>

                <div className="flex flex-col gap-y-6 md:flex-row gap-x-4 items-center pt-14">
                    <div onClick={handleChromeInstall} className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-4 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                        <ChromeSvg />Free - Add to Chrome
                    </div>
                    {/* <a target="_blank" rel="noopener noreferrer" href="https://microsoftedge.microsoft.com/addons/detail/cbmchdndmbmblmepmdlcjinbldhcgccb">
                        <div className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-7 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                            <EdgeSvg />Free - Add to Edge
                        </div>
                    </a> */}
                </div>

                <div className="pt-24 w-full mx-auto">
                    <Carousel>
                        <div>
                            <img src={banner2} alt="banner" />
                        </div>
                        <div>
                            <img src={banner} alt='banner' />
                        </div>
                        <div>
                            <img src={banner1} alt='banner' />
                        </div>
                        {/* <div>
                            <VideoSlide url={"https://youtu.be/fmmuUEvTRPs"} />
                        </div> */}
                    </Carousel>
                </div>

                <Price />
                <FAQ />
                <Guides />

            </div>
        </>
    )
}
export default Home;
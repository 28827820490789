const FAQ = () => {


    const faqList = [
        {
            q: 'What can Map Leads Extractor do for me?',
            a: 'One-click to extract leads from Google Maps result pages, and B2B Lead Generation.'
        },
        {
            q: 'What is the difference between a Free account and a Premium account?',
            a: 'Free account can only extract up to 20 leads, and Premium account can extract Unlimited leads.'
        },
        {
            q: 'How to cancel my subscription?',
            a: 'You can cancel your premium subscription at any time, by clicking the [ cancel subscription ] button, which is located within the order link in the payment email you received when making the payment. The unsubscribe button will end your subscription.'
        }
    ]

    return (
        <>
            <div class="p-8 px-0">
                <div class="bg-white p-4 rounded-lg shadow-xl py-4 mt-12">
                    <h4 class="text-4xl font-bold text-gray-800 tracking-widest uppercase text-center">FAQ</h4>

                    <div class="space-y-12 px-2 xl:px-16 mt-12">

                        {
                            faqList.map((item, index) => {
                                return <div class="mt-4 flex flex-row">
                                    
                                    <div>
                                        <div class="flex items-center h-16 border-l-4 border-black">
                                            <span class="text-2xl text-black px-4">Q.</span>
                                        </div>
                                        <div class="flex items-center h-16 border-l-4 border-gray-400">
                                            <span class="text-2xl text-gray-400 px-4">A.</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div class="flex items-center h-16">
                                            <span class=" text-lg text-black font-bold">{item.q}</span>
                                        </div>
                                        <div class="flex items-center h-16">
                                            <span class=" text-gray-500">{item.a}</span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default FAQ;